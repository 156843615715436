import { JOURNEYTemplate } from './JourneyTemplate';
import { JOURNEY_ESTIMATESTemplate } from './JourneyEstimatesTemplate';
import { INTERACTIVETemplate } from './InteractiveTemplate';
import { ROUTINGTemplate } from './RoutingTemplate';
import { AirlinePerformanceTemplate } from './AirlinePerformance';
import { AGENCYPerformanceTemplate } from './AgencyPerformance';
import { AGENCYBENCHMARKTemplate } from './AgencyBenchmark';
import { ARCCITemplate } from './ArcCiTemplate';
import { ARCCIDSTemplate } from './ArcCiDSTemplate';
import { ADMINTemplate } from './AdminTemplate';
import { CarrierDashboardTemplate } from './CarrierDashboardTemplate';
import { OnDTemplate } from './OnDTemplate';
import { AirlineDemandTemplate } from './AirlineDemandTemplate';

export const templates: any = {
    JOURNEY: { ...JOURNEYTemplate },
    JOURNEY_ESTIMATES: { ...JOURNEY_ESTIMATESTemplate },
    INTERACTIVE: { ...INTERACTIVETemplate },
    ROUTING: { ...ROUTINGTemplate },    
    AIRLINE_PERFORMANCE: { ...AirlinePerformanceTemplate },
    AGENCY_PERFORMANCE: { ...AGENCYPerformanceTemplate },
    BENCHMARK: { ...AGENCYBENCHMARKTemplate },
    ARCCI: { ...ARCCITemplate },
    ARCCIDS: { ...ARCCIDSTemplate },
    CARRIER_DASHBOARD: { ...CarrierDashboardTemplate },
    OND_DEMAND: { ...OnDTemplate },
    AIRLINE_DEMAND: { ...AirlineDemandTemplate },
    ADMIN: { ...ADMINTemplate },
}

// HELP: [list][WRAP, FIELD]
export const dependencies: any = {
    JOURNEY: [
        { listId: 0, keyFrom: [1, 0], valFrom: [0, 0] },
        { listId: 0, keyFrom: [11, 0], valFrom: [12, 0] }
    ],
    JOURNEY_ESTIMATES: [
        { listId: 0, keyFrom: [1, 0], valFrom: [0, 0] },
        { listId: 0, keyFrom: [11, 0], valFrom: [12, 0] }
    ],
    INTERACTIVE: [
        { listId: 0, keyFrom: [2, 0], valFrom: [1, 0] }
    ],
    ROUTING: [
        { listId: 0, keyFrom: [1, 0], valFrom: [0, 0] },
        { listId: 0, keyFrom: [10, 0], valFrom: [11, 0] }
    ],
    AIRLINE_PERFORMANCE: [
        { listId: 0, keyFrom: [1, 0], valFrom: [0, 0] },
        { listId: 0, keyFrom: [11, 0], valFrom: [12, 0] }
    ],
    AGENCY_PERFORMANCE: [
        { listId: 0, keyFrom: [1, 0], valFrom: [0, 0] },
        { listId: 0, keyFrom: [11, 0], valFrom: [12, 0] }
    ],
    BENCHMARK: [
        { listId: 0, keyFrom: [2, 0], valFrom: [0, 0] },
        { listId: 0, keyFrom: [12, 0], valFrom: [13, 0] }
    ],
    ARCCI: [
        { listId: 0, keyFrom: [1, 0], valFrom: [0, 0] },
        { listId: 0, keyFrom: [11, 0], valFrom: [12, 0] }
    ],
    ARC: [
        { listId: 0, keyFrom: [1, 0], valFrom: [0, 0] },
        { listId: 0, keyFrom: [11, 0], valFrom: [12, 0] }
    ],
    OND_DEMAND: [
        { listId: 0, keyFrom: [5, 0], valFrom: [6, 0] }
    ],
    AIRLINE_DEMAND: [
        { listId: 0, keyFrom: [1, 0], valFrom: [0, 0] }
    ],
};

export const ui_dependencies: any = {
    JOURNEY: [
        { type: "date", changeThis: [12, 0], onChange: [11, 0] },
        { type: "date", changeThis: [12, 1], onChange: [11, 0] },
        { type: "date", changeThis: [12, 2], onChange: [11, 0] },
        { type: "date", changeThis: [12, 3], onChange: [11, 0] },
        { type: "date", changeThis: [12, 4], onChange: [11, 0] },
        { type: "date", changeThis: [12, 5], onChange: [11, 0] },

        { type: "source", changeThis: [16, 0], onChange: [22, 0] }
    ],
    JOURNEY_ESTIMATES: [
        { type: "date", changeThis: [12, 0], onChange: [11, 0] },
        { type: "date", changeThis: [12, 1], onChange: [11, 0] },
        { type: "date", changeThis: [12, 2], onChange: [11, 0] },
        { type: "date", changeThis: [12, 3], onChange: [11, 0] },
        { type: "date", changeThis: [12, 4], onChange: [11, 0] },
        { type: "date", changeThis: [12, 5], onChange: [11, 0] },

        { type: "source", changeThis: [16, 0], onChange: [21, 0] }
    ],
    INTERACTIVE: [
        { type: "purchase_date", changeThis: [13, 1], onChange: [] },
        { type: "purchase_date", changeThis: [13, 2], onChange: [] },
        { type: "purchase_date", changeThis: [13, 3], onChange: [] },
        { type: "purchase_date", changeThis: [13, 4], onChange: [] },
        { type: "purchase_date", changeThis: [13, 5], onChange: [] },
        { type: "purchase_date", changeThis: [13, 6], onChange: [] },
        { type: "purchase_date", changeThis: [13, 7], onChange: [] },
        { type: "purchase_date", changeThis: [13, 8], onChange: [] },

        { type: "travel_date", changeThis: [14, 1], onChange: [] },
        { type: "travel_date", changeThis: [14, 2], onChange: [] },
        { type: "travel_date", changeThis: [14, 3], onChange: [] },
        { type: "travel_date", changeThis: [14, 4], onChange: [] },
        { type: "travel_date", changeThis: [14, 5], onChange: [] },
        { type: "travel_date", changeThis: [14, 6], onChange: [] },
        { type: "travel_date", changeThis: [14, 7], onChange: [] },
        { type: "travel_date", changeThis: [14, 8], onChange: [] },

        { type: "source", changeThis: [5, 0], onChange: [17, 0] }
    ],
    ROUTING: [
        { type: "date", changeThis: [11, 0], onChange: [10, 0] },
        { type: "date", changeThis: [11, 1], onChange: [10, 0] },
        { type: "date", changeThis: [11, 2], onChange: [10, 0] },
        { type: "date", changeThis: [11, 3], onChange: [10, 0] },
        { type: "date", changeThis: [11, 4], onChange: [10, 0] },
        { type: "date", changeThis: [11, 5], onChange: [10, 0] },

        { type: "source", changeThis: [16, 0], onChange: [22, 0] }
    ],
    OND_DEMAND: [
        { type: "date", changeThis: [6, 0], onChange: [5, 0] },
        { type: "date", changeThis: [6, 1], onChange: [5, 0] },
        { type: "date", changeThis: [6, 2], onChange: [5, 0] },
        { type: "date", changeThis: [6, 3], onChange: [5, 0] },
        { type: "date", changeThis: [6, 4], onChange: [5, 0] },
        { type: "date", changeThis: [6, 5], onChange: [5, 0] }
    ],
    AIRLINE_DEMAND: [
        { type: "date", changeThis: [6, 0], onChange: [5, 0] },
        { type: "date", changeThis: [6, 1], onChange: [5, 0] },
        { type: "date", changeThis: [6, 2], onChange: [5, 0] },
        { type: "date", changeThis: [6, 3], onChange: [5, 0] },
        { type: "date", changeThis: [6, 4], onChange: [5, 0] },
        { type: "date", changeThis: [6, 5], onChange: [5, 0] }
    ],
    AIRLINE_PERFORMANCE: [
        { type: "date", changeThis: [13, 0], onChange: [11, 0] },
        { type: "date", changeThis: [13, 1], onChange: [11, 0] },
        { type: "date", changeThis: [13, 2], onChange: [11, 0] },
        { type: "date", changeThis: [13, 3], onChange: [11, 0] },
        { type: "date", changeThis: [13, 4], onChange: [11, 0] },

        { type: "date_prior", changeThis: [13, 0], onChange: [12, 0] },

        { type: "source", changeThis: [18, 0], onChange: [20, 0] }
    ],
    AGENCY_PERFORMANCE: [
        { type: "date", changeThis: [13, 0], onChange: [11, 0] },
        { type: "date", changeThis: [13, 1], onChange: [11, 0] },
        { type: "date", changeThis: [13, 2], onChange: [11, 0] },
        { type: "date", changeThis: [13, 3], onChange: [11, 0] },
        { type: "date", changeThis: [13, 4], onChange: [11, 0] },

        { type: "date_prior", changeThis: [13, 0], onChange: [12, 0] },

        { type: "source", changeThis: [18, 0], onChange: [20, 0] }
    ],
    BENCHMARK: [
        { type: "date", changeThis: [14, 0], onChange: [12, 0] },
        { type: "date", changeThis: [14, 1], onChange: [12, 0] },
        { type: "date", changeThis: [14, 2], onChange: [12, 0] },
        { type: "date", changeThis: [14, 3], onChange: [12, 0] },
        { type: "date", changeThis: [14, 4], onChange: [12, 0] },

        { type: "date_prior", changeThis: [14, 0], onChange: [13, 0] },

        { type: "source", changeThis: [19, 0], onChange: [21, 0] }
    ],
    ARCCI: [],
    ARCCIDS: [
        { type: "travel_date", changeThis: [9, 1], onChange: [] },
        { type: "travel_date", changeThis: [10, 1], onChange: [] },
        { type: "travel_date", changeThis: [11, 1], onChange: [] },
        { type: "travel_date", changeThis: [12, 1], onChange: [] },
    ],
    ARC: []
};